import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import flecha from "../../images/navigation/flecha.png";
import firstCover from "../../images/bookCovers/cover-1.jpg";
import secondCover from "../../images/bookCovers/cover-2.jpg";
import TextDescription from "./TextDescription";
const blackbookMeta = [
	{
		alt_text: "This is the first BlackBook with a collection of original graffiti",
		link: "book/1",
		src: firstCover
	},
	{
		alt_text: "This is the second BlackBook with a collection of original graffiti",
		link: "book/2",
		src: secondCover
	}
];

let styles = {
	arrow: {
		width: "100px",
		position: "fixed",
		bottom: 0,
		left: 0
	}
};

function Galeria() {
	return (
		<div id="galeria-wrapper">
			<Container fluid>
				<Row className="justify-content-center align-items-center mb-3 mr-0 ml-0">
					<TextDescription />
				</Row>
        <Row className="justify-content-center">
          {blackbookMeta &&
            blackbookMeta.map((data, i) => (
              // TODO: It may be possible that the Col sizes will have to be dynamic based on stateful values of viewport orientation
                <Col key={`galeria-col-${i}`} xs="auto" lg="12" className="my-auto d-flex justify-content-center align-items-center">
                  <Link
                    to={data.link}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%"
                    }}
                  >
                    <img
                      src={data.src}
                      className="blackbook-cover"
                      key={`galeria-img-${i}`}
                      alt={data.alt_text}
                    />
                  </Link>
                </Col>
            ))}
          </Row>
				<Link to="/">
					<img style={styles.arrow} src={flecha} />
				</Link>
			</Container>
		</div>
	);
}

export default Galeria;
