import { Fragment } from "react";

function SocialIcon({ title, url, children }) {
	return (
		<Fragment>
			<a href={url} target="_blank" title={title} rel="noreferrer">
				{children}
			</a>
		</Fragment>
	);
}

export default SocialIcon;
