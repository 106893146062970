const blackbooks = {
  first: [
    {
      name: "001_Cover_Edit.jpg",
      author: "001_Cover_Edit.jpg",
      url: "001_Cover_Edit.jpg"
    },
    {
      name: "002_003_Mixed_Edit.jpg",
      author: "002_003_Mixed_Edit.jpg",
      url: "002_003_Mixed_Edit.jpg"
    },
    {
      name: "004_0283_009_0010_Gabz_Edit.jpg",
      author: "004_0283_009_0010_Gabz_Edit.jpg",
      url: "004_0283_009_0010_Gabz_Edit.jpg"
    },
    {
      name: "005_006_DRMOL_Edit.jpg",
      author: "005_006_DRMOL_Edit.jpg",
      url: "005_006_DRMOL_Edit.jpg"
    },
    {
      name: "008_Gabz_Edit.jpg",
      author: "008_Gabz_Edit.jpg",
      url: "008_Gabz_Edit.jpg"
    },
    {
      name: "010_0113Tamaroots_Edit.jpg",
      author: "010_0113Tamaroots_Edit.jpg",
      url: "010_0113Tamaroots_Edit.jpg"
    },
    {
      name: "012_SEB_013_Kron_Edit.jpg",
      author: "012_SEB_013_Kron_Edit.jpg",
      url: "012_SEB_013_Kron_Edit.jpg"
    },
    {
      name: "014_015_COZ_Edit.jpg",
      author: "014_015_COZ_Edit.jpg",
      url: "014_015_COZ_Edit.jpg"
    },
    {
      name: "016_Nosone_017_unknown.jpg",
      author: "016_Nosone_017_unknown.jpg",
      url: "016_Nosone_017_unknown.jpg"
    },
    {
      name: "018_019_muffinman_Edit.jpg",
      author: "018_019_muffinman_Edit.jpg",
      url: "018_019_muffinman_Edit.jpg"
    },
    {
      name: "020_021_URNEWYORK_Edit.jpg",
      author: "020_021_URNEWYORK_Edit.jpg",
      url: "020_021_URNEWYORK_Edit.jpg"
    },
    {
      name: "022_Poke_023_CEL.jpg",
      author: "022_Poke_023_CEL.jpg",
      url: "022_Poke_023_CEL.jpg"
    },
    {
      name: "024_0025_STATUS_Edit.jpg",
      author: "024_0025_STATUS_Edit.jpg",
      url: "024_0025_STATUS_Edit.jpg"
    },
    {
      name: "026_Free5_029_Unknown.jpg",
      author: "026_Free5_029_Unknown.jpg",
      url: "026_Free5_029_Unknown.jpg"
    },
    {
      name: "027_028_STANLEESTEEL_Edit.jpg",
      author: "027_028_STANLEESTEEL_Edit.jpg",
      url: "027_028_STANLEESTEEL_Edit.jpg"
    },
    {
      name: "030_031_UNKNOWN_Edit.jpg",
      author: "030_031_UNKNOWN_Edit.jpg",
      url: "030_031_UNKNOWN_Edit.jpg"
    },
    {
      name: "032_033_URNY_Spread_Edit.jpg",
      author: "032_033_URNY_Spread_Edit.jpg",
      url: "032_033_URNY_Spread_Edit.jpg"
    },
    {
      name: "034_Dio_037_DIO.jpg",
      author: "034_Dio_037_DIO.jpg",
      url: "034_Dio_037_DIO.jpg"
    },
    {
      name: "040_041_BLOONDEL_Edit.jpg",
      author: "040_041_BLOONDEL_Edit.jpg",
      url: "040_041_BLOONDEL_Edit.jpg"
    },
    {
      name: "042_043_MRLETTA_Edit.jpg",
      author: "042_043_MRLETTA_Edit.jpg",
      url: "042_043_MRLETTA_Edit.jpg"
    },
    {
      name: "044_DRONE_038_DIO.jpg",
      author: "044_DRONE_038_DIO.jpg",
      url: "044_DRONE_038_DIO.jpg"
    },
    {
      name: "046_047_UNKNOWN_Edit.jpg",
      author: "046_047_UNKNOWN_Edit.jpg",
      url: "046_047_UNKNOWN_Edit.jpg"
    },
    {
      name: "049_050_SK_Edit.jpg",
      author: "049_050_SK_Edit.jpg",
      url: "049_050_SK_Edit.jpg"
    },
    {
      name: "051_SLAE_052_Freco.jpg",
      author: "051_SLAE_052_Freco.jpg",
      url: "051_SLAE_052_Freco.jpg"
    },
    {
      name: "053_054_FRECO_Edit.jpg",
      author: "053_054_FRECO_Edit.jpg",
      url: "053_054_FRECO_Edit.jpg"
    },
    {
      name: "055_056_FRECO_Edit.jpg",
      author: "055_056_FRECO_Edit.jpg",
      url: "055_056_FRECO_Edit.jpg"
    },
    {
      name: "058_GORB_Edit.jpg",
      author: "058_GORB_Edit.jpg",
      url: "058_GORB_Edit.jpg"
    },
    {
      name: "059_060_Rous_Edit.jpg",
      author: "059_060_Rous_Edit.jpg",
      url: "059_060_Rous_Edit.jpg"
    },
    {
      name: "062_Unknown_061_Unknown.jpg",
      author: "062_Unknown_061_Unknown.jpg",
      url: "062_Unknown_061_Unknown.jpg"
    },
    {
      name: "063_064_Shak_Edit.jpg",
      author: "063_064_Shak_Edit.jpg",
      url: "063_064_Shak_Edit.jpg"
    },
    {
      name: "065_Grat_066_Unknown.jpg",
      author: "065_Grat_066_Unknown.jpg",
      url: "065_Grat_066_Unknown.jpg"
    },
    {
      name: "067_Feegz_068_feegz.jpg",
      author: "067_Feegz_068_feegz.jpg",
      url: "067_Feegz_068_feegz.jpg"
    },
    {
      name: "069_celo_070_arte_TSC.jpg",
      author: "069_celo_070_arte_TSC.jpg",
      url: "069_celo_070_arte_TSC.jpg"
    },
    {
      name: "071_072_GraffitiSapiens_Edit.jpg",
      author: "071_072_GraffitiSapiens_Edit.jpg",
      url: "071_072_GraffitiSapiens_Edit.jpg"
    },
    {
      name: "073_DART_076_Butter.jpg",
      author: "073_DART_076_Butter.jpg",
      url: "073_DART_076_Butter.jpg"
    },
    {
      name: "074_075_DART.jpg",
      author: "074_075_DART.jpg",
      url: "074_075_DART.jpg"
    },
    {
      name: "077_078_Butter_Edit.jpg",
      author: "077_078_Butter_Edit.jpg",
      url: "077_078_Butter_Edit.jpg"
    },
    {
      name: "079_080_Bella_Edit.jpg",
      author: "079_080_Bella_Edit.jpg",
      url: "079_080_Bella_Edit.jpg"
    },
    {
      name: "081_Bella_082_Unknown.jpg",
      author: "081_Bella_082_Unknown.jpg",
      url: "081_Bella_082_Unknown.jpg"
    },
    {
      name: "083_Unknown_084_CHEO.jpg",
      author: "083_Unknown_084_CHEO.jpg",
      url: "083_Unknown_084_CHEO.jpg"
    },
    {
      name: "085_086_CHEO_Edit.jpg",
      author: "085_086_CHEO_Edit.jpg",
      url: "085_086_CHEO_Edit.jpg"
    },
    {
      name: "087_SUMA_088_Free5.jpg",
      author: "087_SUMA_088_Free5.jpg",
      url: "087_SUMA_088_Free5.jpg"
    },
    {
      name: "089_090_Miscellaneous_Edit.jpg",
      author: "089_090_Miscellaneous_Edit.jpg",
      url: "089_090_Miscellaneous_Edit.jpg"
    },
    {
      name: "091_BackCover.jpg",
      author: "091_BackCover.jpg",
      url: "091_BackCover.jpg"
    }
  ],
  second: [
    {
      name: "001_Cover_Edit.jpg",
      author: "001_Cover_Edit.jpg",
      url: "001_Cover_Edit.jpg"
    },
    {
      name: "003_Andy_Spread_Edit.jpg",
      author: "003_Andy_Spread_Edit.jpg",
      url: "003_Andy_Spread_Edit.jpg"
    },
    {
      name: "004_005_Various_Edit.jpg",
      author: "004_005_Various_Edit.jpg",
      url: "004_005_Various_Edit.jpg"
    },
    {
      name: "006_Feegz_007_Firo.jpg",
      author: "006_Feegz_007_Firo.jpg",
      url: "006_Feegz_007_Firo.jpg"
    },
    {
      name: "008_Slae_011_Lapis.jpg",
      author: "008_Slae_011_Lapis.jpg",
      url: "008_Slae_011_Lapis.jpg"
    },
    {
      name: "009_010_Unknown_Edit.jpg",
      author: "009_010_Unknown_Edit.jpg",
      url: "009_010_Unknown_Edit.jpg"
    },
    {
      name: "012_013_Unknown_edit.jpg",
      author: "012_013_Unknown_edit.jpg",
      url: "012_013_Unknown_edit.jpg"
    },
    {
      name: "014_Irso_017_Unknown.jpg",
      author: "014_Irso_017_Unknown.jpg",
      url: "014_Irso_017_Unknown.jpg"
    },
    {
      name: "015_016_Coz_Edit.jpg",
      author: "015_016_Coz_Edit.jpg",
      url: "015_016_Coz_Edit.jpg"
    },
    {
      name: "018_Unknown_019_Unknown.jpg",
      author: "018_Unknown_019_Unknown.jpg",
      url: "018_Unknown_019_Unknown.jpg"
    },
    {
      name: "020_021_Bella_Edit.jpg",
      author: "020_021_Bella_Edit.jpg",
      url: "020_021_Bella_Edit.jpg"
    },
    {
      name: "022_Stikers_025_SEM.jpg",
      author: "022_Stikers_025_SEM.jpg",
      url: "022_Stikers_025_SEM.jpg"
    },
    {
      name: "023_024_tracker_Edit.jpg",
      author: "023_024_tracker_Edit.jpg",
      url: "023_024_tracker_Edit.jpg"
    },
    {
      name: "026_027_Unknown_Edit.jpg",
      author: "026_027_Unknown_Edit.jpg",
      url: "026_027_Unknown_Edit.jpg"
    },
    {
      name: "028_029_Feegz_Edit.jpg",
      author: "028_029_Feegz_Edit.jpg",
      url: "028_029_Feegz_Edit.jpg"
    },
    {
      name: "030_h20_031_Dister.jpg",
      author: "030_h20_031_Dister.jpg",
      url: "030_h20_031_Dister.jpg"
    },
    {
      name: "032_033_Bone_Edit.jpg",
      author: "032_033_Bone_Edit.jpg",
      url: "032_033_Bone_Edit.jpg"
    },
    {
      name: "034_Bone_035_Bone.jpg",
      author: "034_Bone_035_Bone.jpg",
      url: "034_Bone_035_Bone.jpg"
    },
    {
      name: "036_blintage_041_Leslie.jpg",
      author: "036_blintage_041_Leslie.jpg",
      url: "036_blintage_041_Leslie.jpg"
    },
    {
      name: "037_038_Cross_Edit.jpg",
      author: "037_038_Cross_Edit.jpg",
      url: "037_038_Cross_Edit.jpg"
    },
    {
      name: "039_040_Alexguerrero_Edit.jpg",
      author: "039_040_Alexguerrero_Edit.jpg",
      url: "039_040_Alexguerrero_Edit.jpg"
    },
    {
      name: "042_Unknown_045_Isro.jpg",
      author: "042_Unknown_045_Isro.jpg",
      url: "042_Unknown_045_Isro.jpg"
    },
    {
      name: "043_044_Pepe_Edit.jpg",
      author: "043_044_Pepe_Edit.jpg",
      url: "043_044_Pepe_Edit.jpg"
    },
    {
      name: "046_047_Feegz_Edit.jpg",
      author: "046_047_Feegz_Edit.jpg",
      url: "046_047_Feegz_Edit.jpg"
    },
    {
      name: "048_Leslie_049_Unknown.jpg",
      author: "048_Leslie_049_Unknown.jpg",
      url: "048_Leslie_049_Unknown.jpg"
    },
    {
      name: "050_0283_051_Bad6.jpg",
      author: "050_0283_051_Bad6.jpg",
      url: "050_0283_051_Bad6.jpg"
    },
    {
      name: "052_Badone_.jpg",
      author: "052_Badone_.jpg",
      url: "052_Badone_.jpg"
    },
    {
      name: "054_055_Edit.jpg",
      author: "054_055_Edit.jpg",
      url: "054_055_Edit.jpg"
    },
    {
      name: "056_Back_horizontal.jpg",
      author: "056_Back_horizontal.jpg",
      url: "056_Back_horizontal.jpg"
    }
  ]
};

export default blackbooks;
