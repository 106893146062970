import { Fragment } from "react";
import { Container } from "reactstrap";
import { Row, Col } from "reactstrap";
import AboutContent from "../../Components/AboutContent/AboutContent";

function About({ xsOff, children }) {
	return (
		<Fragment>
			<AboutContent />;
			<Container className="mt-5 about-people">
				<Row>
					<Col
						xs={{ size: 8, offset: 3 }}
						md={{ size: 4, offset: 4 }}
						lg={{ size: 3, offset: 5 }}
						className="mt-0 mb-2"
					>
						{children}
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
}

export default About;
