import { Fragment, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Icon } from "semantic-ui-react";
import * as about_content from "../../static_meta/about-content.json";
import qrcode from "../../images/donation/qrcode.png";

let styles = {
	aboutParagraph: {
		width: "100%",
		height: "100%",
		overflow: "auto",
		fontSize: "16px"
	},
	donate_para: {
		width: "100%",
		height: "100%",
		overflow: "auto",
		fontSize: "20px"
	},
	link: {
		color: "white"
	}
};

function AboutContent() {
	const [scrolling, setScrolling] = useState(true);

	const onScroll = e => {
		if (e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight)
			setScrolling(!scrolling);
		else if (e.target.scrollTop === 0) setScrolling(!scrolling);
	};

	return (
		<Fragment>
			<Container className="mt-4">
				<Col>
					<Row>
						<h1 className="text-white">Ephímero: una perspectiva dominicana del graffiti.</h1>
					</Row>
					<Row>
						<Col className="donate-col-summary">
							<Row>
								{about_content.content.map(paragraph => {
									return <p style={styles.donate_para}>{paragraph}</p>;
								})}
								<div className="dossier-link-container">
									<button className="dossier-link">
										<a
											target="_blank"
											href="https://docs.google.com/document/d/1gqisAHZzYbgR-DXGq9BMSnCzjXrIOJzWwdKE0W6WKKQ/edit?usp=sharing"
											rel="noopener noreferrer"
										>
											Dossier
										</a>
									</button>
								</div>
							</Row>
						</Col>
					</Row>
				</Col>
			</Container>
			<Container>
				<div>
					<Col>
						<Row>
							<h1 className="text-white">Contribuye a la cultura dominicana:</h1>
							<div style={styles.donate_para} className="text-white">
								<p>
									Necesitamos tu apoyo para asegurar que colaboradores y artistas reciban
									remuneración financiera por su trabajo en Ephímero.
								</p>
								<p>
									Al donar nos ayudas a continuar nuestra labor de compartir y generar conocimiento
									de forma colectiva, horizontal y accesible.
								</p>
							</div>
						</Row>
						<div className="justify-content-md-center">
							<Row>
								<Col className="donate-col">
									<Row>
										<Col>
											<h1 className="text-white">
												<div className="donate-btn-inverse-container">
													<button className="donate-btn-inverse">
														<a
															target="_blank"
															href="https://PayPal.Me/murcielagosfumando"
															rel="noopener noreferrer"
														>
															Paypal
														</a>
													</button>
												</div>
											</h1>
										</Col>
									</Row>
									<Row>
										<Col>
											<div>
												<a
													className="qr-link"
													target="_blank"
													href="http://PayPal.Me/murcielagosfumando"
													rel="noopener noreferrer"
												>
													<img className="qr" src={qrcode}></img>
												</a>
											</div>
										</Col>
									</Row>
								</Col>
								<Col className="donate-col text-white">
									<h1 className="text-white">Transferencia bancaria:</h1>
									<h2>Cuenta Banco Popular Dominicano</h2>
									<h2>Nombre: Stephanie Marie Gómez Jiménez</h2>
									<h2>
										Número de cuenta <u>830421590</u>
									</h2>
									<h2>Tipo de cuenta: Ahorros - Corriente</h2>
								</Col>
								<Col className="donate-col">
									<h1 className="text-white">
										<div className="donate-btn-inverse-container">
											<button className="donate-btn-inverse">
												<a
													style={styles.link_emphasis}
													target="_blank"
													href="https://patreon.com/Ephimero?utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator&utm_content=join_link"
													rel="noopener noreferrer"
												>
													Patreon
												</a>
											</button>
										</div>
									</h1>
									<h2 className="text-white">Al suscribirte en Patreon recibirás beneficios.</h2>
								</Col>
							</Row>
						</div>
					</Col>
				</div>
			</Container>
			<Container className="mt-5 about-people">
				<div className="scroll-down-div">
					<Icon
						className="scroll-down-icon"
						inverted
						color="grey"
						name={scrolling ? "angle double down" : "angle double up"}
						size="large"
					></Icon>
				</div>
			</Container>
		</Fragment>
	);
}

export default AboutContent;
