import { useState, useEffect, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Book } from "../../Components/";
import blackbooks from "../../utils/blackbooks/names";
import flecha from "../../images/navigation/flecha.png";

let styles = {
  blackbook: {
    backgroundColor: "black",
    minHeight: "100vh",
    minWidth: "10vw"
  },
  flecha: {
    width: "200px",
    height: "auto"
  },
  bottomRow: {
    position: "fixed",
    bottom: "0"
  },
  blackbookContainer: {
    paddingTop: "20px"
  }
};

const collection = [blackbooks.first, blackbooks.second];

function BlackBook({ bookNumber }) {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [bookDimensions, setBookDimensions] = useState({
    width: windowDimensions.width * 0.6,
    height: windowDimensions.width * 0.6 * 0.647 // general aspect ratio of Flip Page in relation to book dimensions
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });

      const windowInnerWidth = window.innerWidth;
      const windowInnerHeight = window.innerHeight;
      const landscape = windowInnerWidth > windowInnerHeight;
      const largeScreen = windowInnerWidth > 900 && landscape;
      const largeMediumScreen =
        windowInnerWidth > 700 && windowInnerWidth < 900 && landscape;
      const mediumScreen =
        windowInnerWidth < 700 && windowInnerWidth > 600 && landscape;
      const smallScreen = windowInnerWidth < 600 && landscape;

      if (largeScreen) {
        setBookDimensions({
          width: 700,
          height: 700 * 0.647
        });
      } else if (largeMediumScreen) {
        setBookDimensions({
          width: 450,
          height: 450 * 0.647
        });
      } else if (mediumScreen) {
        setBookDimensions({
          width: 400,
          height: 400 * 0.647
        });
      } else if (smallScreen) {
        setBookDimensions({
          width: 350,
          height: 350 * 0.647
        });
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bookDimensions, windowDimensions]);

  return (
    <div style={styles.blackbook}>
      <Container style={styles.blackbookContainer}>
        {windowDimensions.width < windowDimensions.height ? (
          <Row>
            <Col xs={{ size: 8, offset: 2 }} className="mt-4 mb-2">
              <h3 className="text-white mt-5">
                Please flip your device for the best blackbook experience
              </h3>
            </Col>
          </Row>
        ) : (
          <Fragment>
            <Row>
              <Col></Col>
              <Col>
                <Book
                  width={bookDimensions.width}
                  height={bookDimensions.height}
                  pageNumber={0}
                  piezas={collection[bookNumber - 1]}
                  bookNumber={bookNumber}
                />
              </Col>
              <Col></Col>
            </Row>
          </Fragment>
        )}
      </Container>
      <Row style={styles.bottomRow}>
        <Col xs={{ size: 1, offset: 1 }}>
          <Link to="/galeria">
            <img className="img-flecha" src={flecha} />
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default BlackBook;
