function TextDescription() {
	return (
		<div>
			<h4 className="text-white">
				Blackbooks have an historical relevance in graffiti culture; they have served as mobile
				schools and museums. They are sketchbooks that graffiti writers carry around and lend to
				other artists. Borrowing from this significance, we started two blackbooks to initiate a
				dialogue between established and emerging Dominican artists from NYC, Puerto Rico, and on
				the island. Both books were digitized in 2019 and programmed for a web-browsing experience
				thanks to a grant from Loisaida Center.
			</h4>
		</div>
	);
}

export default TextDescription;
