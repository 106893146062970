import FlipPage from "react-flip-page";

let styles = {
  pieza: {
    width: "100%",
    position: "relative",
    height: "auto"
  }
};

function Book({ width, height, pageNumber, bookNumber, piezas }) {
  return (
    //for testing cross-browser deployment, dimensions are fixed
    <FlipPage
      showSwipeHint
      orientation={"horizontal"}
      width={width}
      height={height}
      startAt={pageNumber}
      pageBackground={"black"}
    >
      {piezas.map((pieza, i) => (
        <img
          style={styles.pieza}
          key={`pieza-${i}`}
          src={`https://efimero.s3.amazonaws.com/piezas/BlackBooks${bookNumber}/${pieza.name}`}
        />
      ))}
    </FlipPage>
  );
}

export default Book;
