import ContainerRow from "./ContainerRow/ContainerRow";
import NavigationRow from "./NavigationRow/NavigationRow";

function Grid({
	children,
	style,
	nextLink,
	previousLink,
	gridClass,
	top,
	nextLinkStyle,
	arrowClass
}) {
	return (
		<div className={gridClass} style={style}>
			<ContainerRow>{children}</ContainerRow>
			<NavigationRow
				nextLink={nextLink}
				previousLink={previousLink}
				top={top}
				nextLinkStyle={nextLinkStyle}
				arrowClass={arrowClass}
			/>
		</div>
	);
}

export default Grid;
