import { Row, Container } from "reactstrap";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo-dossier-cropped.png";
import flecha from "../../images/navigation/flecha.png";

import HomeContent from "../../Components/HomeContent";

let styles = {
	arrow: {
		transform: "rotate(180deg)",
		width: "100px"
	}
};

let authors_meta = [
	{
		name: "Murciélagos Fumando",
		title: "Un proyecto de",
		href: "https://www.instagram.com/ephimero_archive/",
		delimiter: true
	},
	{
		name: "Alberto Vargas",
		title: "Diseño web:",
		href: "https://a-vargasmarte.github.io/",
		delimiter: true
	},
	{
		name: "Marie Jimenez",
		title: "Producción:",
		href: "http://www.mariejimenes.com",
		delimiter: true
	},
	{
		name: "Carlos Jesús Martinez Dominguez",
		title: "Idea original y Coordinador:",
		href: "https://feegz.wordpress.com/about-feegz/",
		delimiter: false
	}
];

function Home() {
	return (
		<Fragment>
			<Row className="justify-content-center align-items-center text-align-center m-0">
				<Container className="home-container">
					<Row className="mt-5 justify-content-center align-items-center mr-0 ml-0">
						<img className="logo" src={logo}></img>
					</Row>

					<Row className="mt-0 mr-0 ml-0">
						<HomeContent></HomeContent>
					</Row>
					<Row className="mt-5 mb-5 justify-content-center align-items-center mr-0 ml-0">
						<Link to="/galeria">
							<img style={styles.arrow} src={flecha} />
						</Link>
					</Row>
					<Row className="mr-0 ml-0 justify-content-center align-items-center">
						<h4 className="text-white">
							For up to date news of the project check out our instagram page{" "}
							<a target="_blank" href={"https://www.instagram.com/ephimero_archive/"} rel={"noreferrer"}>
								@ephimero_archive
							</a>
						</h4>
					</Row>
					<Row className="mt-2 mr-0 ml-0 mb-5 justify-content-center align-items-center">
						<h4 className="text-white text-align-center">
							{authors_meta &&
								authors_meta.map(author => (
									<span>
										{author.title}{" "}
										<a target="_blank" href={author.href} rel={"noreferrer"}>
											{author.name}
										</a>
										{author.delimiter ? " | " : " "}
									</span>
								))}
						</h4>
					</Row>
				</Container>
			</Row>
		</Fragment>
	);
}

export default Home;
