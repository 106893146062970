import { Container, Row } from "reactstrap";

function HomeContent() {
	return (
		<Container className="mt-0">
			<Row className="justify-content-center align-items-center m-0">
				<h1 className="text-white text-align-center">Ephímero: A Dominican Graff Perspective</h1>
			</Row>
			<Row className="justify-content-center align-items-center mt-5 mr-0 ml-0">
				<h5 className="text-white text-align-center">
					Published in 2019 to shine a light on Dominicans in graffiti.
					<br />
					An archive from 2013 to 2016 on
					<br />
					Dominicans in NYC and Dominicans in the island
				</h5>
			</Row>
			<Row className="justify-content-center align-items-center mt-5 mr-0 ml-0">
				<h4 className="text-white text-align-center">
					Check out the interviews in our youtube channel{" "}
					<a target="_blank" href={"https://www.youtube.com/@ephimeroarchive"} rel={"noreferrer"}>
						@ephimeroarchive
					</a>
					<br />
					and follow the arrow to view the blackbooks.
				</h4>
			</Row>
		</Container>
	);
}

export default HomeContent;
