import flecha from "../../../images/navigation/flecha.png";
import { Link } from "react-router-dom";
import { Fragment } from "react";

let styles = {
  bottomRow: {
    position: "fixed",
    bottom: "0",
    right: "0 !important",
    width: "100%"
  },
  link: {
    position: "fixed",
    bottom: "0px",
    left: "0px"
  },
  nextLink: {
    transform: "rotate(180deg)",
    position: "fixed",
    bottom: "5px",
    right: "0px"
  }
};

function NavigationRow({ previousLink, nextLink, nextLinkStyle, arrowClass }) {
  return (
    <Fragment>
      {previousLink && (
        <Link to={previousLink} style={styles.link}>
          <img className={arrowClass} src={flecha} />
        </Link>
      )}
      {nextLink && (
        <Link to={nextLink} style={nextLinkStyle}>
          <img className={arrowClass} src={flecha} />
        </Link>
      )}
    </Fragment>
  );
}

export default NavigationRow;
