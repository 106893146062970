import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Home, Galeria, BlackBook } from "./Pages";

function App() {
	return (
		<BrowserRouter>
			{/* A <Switch> looks through its children <Switch>s and
            renders the first one that matches the current URL.*/}
			<Switch>
				<Route exact path="/">
					<Home></Home>
				</Route>
				<Route path="/Galeria">
					<Galeria />
				</Route>
				<Route path="/book/1">
					<BlackBook bookNumber={1} />
				</Route>
				<Route path="/book/2">
					<BlackBook bookNumber={2} />
				</Route>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
