import { Container } from "reactstrap";

const styles = {
  container: {
    textAlign: "left"
  }
};

function ContainerRow({ children }) {
  return (
    <Container style={styles.container} className="mx-auto">
      {children}
    </Container>
  );
}

export default ContainerRow;
